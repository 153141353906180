<template>
  <div id="alertChart" style="height: 180px; width: 100%;"></div>
</template>

<script>
import { Chart } from '@antv/g2'

let chart = null
let view = null

export default {
  name: 'AlertChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    totalValue () {
      let sum = 0
      if (this.chartData.length !== 0) {
        this.chartData.forEach(item => {
          sum += item.value
        })
      }
      return sum
    }
  },
  mounted () {
    chart = new Chart({
      container: 'alertChart',
      autoFit: true,
      appendPadding: [0, 40, 0, 0]
    })
    chart.coordinate('theta', {
      radius: 0.9,
      innerRadius: 0.65
    })
    chart.tooltip(false)
    chart.legend({
      position: 'right',
      offsetX: -30,
      itemValue: {
        formatter: (text, arr, index) => {
          return this.chartData[index].value
        }
      }
    })
    chart
      .interval()
      .adjust('stack')
      .position('value')
      .color('name', ['#52C41A', '#FF4D4F'])
      .style({
        fillOpacity: 1,
        lineWidth: 5,
        stroke: '#ffffff'
      })
    chart.removeInteraction('legend-filter')
    chart.interaction('element-active')

    view = chart.createView()
    chart.on('element:statechange', e => {
      const { state, stateStatus, element } = e.gEvent.originalEvent
      if (state === 'active') {
        const data = element.getData()
        if (stateStatus) {
          this.updateAnnotation(data.name, data.value)
        } else {
          this.updateAnnotation('告警总数', this.totalValue)
        }
      }
    })
    this.render()
  },
  methods: {
    updateAnnotation (name, value) {
      view.annotation().clear(true)
      view
        .annotation()
        .text({
          position: ['50%', '48%'],
          content: name,
          style: {
            fontSize: 14,
            fontWeight: 500,
            fill: '#292929',
            textAlign: 'center'
          },
          offsetY: -10
        })
        .text({
          position: ['50%', '57%'],
          content: value,
          style: {
            fontSize: 20,
            fill: '#096DD9',
            fontWeight: 500,
            textAlign: 'center'
          },
          offsetY: 2
        })
      view.render(true)
    },
    render () {
      chart.changeData(this.chartData)
      this.updateAnnotation('告警总数', this.totalValue)
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>

<style></style>
