<template>
  <div id="service-dashboard-detail-container">
    <a-row>
      <a-col :span="24" style="margin-bottom: 8px;">
        <div class="module-container" style="height: 60px; padding: 16px 0;">
          <a-space>
            <a-button
              @click="$router.push({
                name: 'ServiceDashboardList'
              })"
              type="link"
              style="font-size: 18px;"
            >
              <a-icon type="arrow-left"></a-icon>
            </a-button>
            <header style="font-size: 20px; margin-left: -12px; font-weight: 600; color: rgba(0,0,0,.85);margin-top: -2px;">{{detail.name}}</header>
          </a-space>
        </div>
      </a-col>
    </a-row>
    <a-tabs v-model="activeTab" :animated="false">
      <a-tab-pane key="overview" tab="总览">
        <a-row :gutter="16">
          <!-- 关联资源 -->
          <a-col :md="24" :xl="10" style="margin-bottom: 16px">
            <div class="relevance-source-container module-container">
              <div class="module-header" style="margin-bottom: 24px">
                关联资源
              </div>
              <a-col :span="8" v-for="type in relevanceSourceType" :key="type">
                <source-count-card
                  :type="type"
                  :service="detail"
                ></source-count-card>
              </a-col>
            </div>
          </a-col>
          <!-- 当前告警 -->
          <a-col :md="24" :xl="14" style="margin-bottom: 16px">
            <div class="current-alert-container module-container">
              <div class="module-header" style="margin-bottom: 24px">
                当前告警
              </div>
              <div style="position: absolute; right: 20px; top: 17px"></div>
              <severity-radio-group
                style="position: absolute; top: 16px; right: 20px"
                v-model="currentSeverity"
              ></severity-radio-group>
              <alert-timeline
                :severity="currentSeverity"
                @total="v => (currentAlertCount = v)"
                :serviceId="serviceId"
                class="overflow-scroll-hidden"
                style="padding: 10px 4px 0 10px; overflow: auto; height: 275px"
              ></alert-timeline>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <!-- 业务拓扑 -->
          <a-col :md="24" :xl="12" style="margin-bottom: 16px">
            <div class="service-topology-container module-container">
              <div class="module-header" style="margin-bottom: 24px">
                业务拓扑
              </div>
              <status-question-icon
                :is-service-topology="true"
                :is-in-table="false"
                style="position: absolute; left: 30px; top: 58px"
              ></status-question-icon>
              <a-button
                @click="
                  $router.push({
                    name: 'ServiceDashboardTopologyDetail',
                    params: {
                      id: detail.id
                    }
                  })
                "
                style="position: absolute; top: 16px; right: 108px"
                ><a-icon
                  type="fullscreen"
                  style="font-size: 16px; vertical-align: middle"
                />全屏</a-button
              >
              <a-button
                @click="
                  $router.push({
                    name: 'ServiceDashboardTopologyEdit',
                    params: {
                      id: detail.id
                    }
                  })
                "
                style="
                  position: absolute;
                  top: 16px;
                  right: 16px;
                  color: #f6a90f;
                "
              >
                <a-icon type="edit" style="font-size: 16px; vertical-align: middle" />
                编辑
              </a-button>
              <service-topology :cells="detail.topology"></service-topology>
            </div>
          </a-col>
          <!-- 告警统计 -->
          <a-col :md="12" :xl="6" style="margin-bottom: 16px">
            <div class="alert-statistics-container module-container">
              <div class="module-header" style="margin-bottom: 24px">
                告警统计
              </div>
              <div style="position: absolute; right: 16px; top: 11px">
                <simple-select
                  :options="severityOptions"
                  @input="v => (alertCountSeverity = v.value)"
                ></simple-select>
              </div>
              <alert-chart :chartData="alertCountData"></alert-chart>
            </div>
          </a-col>
          <!-- 业务信息 -->
          <a-col :md="12" :xl="6" style="margin-bottom: 16px">
            <div class="service-info-container module-container">
              <div class="module-header" style="margin-bottom: 10px">
                业务信息
              </div>
              <div class="service-info">
                <a-descriptions :column="1">
                  <template #title>
                    <p class="header-title">{{ detail.name }}</p>
                    <p class="header-description">业务名称</p>
                  </template>
                  <a-descriptions-item>
                    <template #label>
                      <a-icon type="calendar" />
                      <span>创建时间</span>
                    </template>
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item>
                    <template #label>
                      <a-icon type="user" />
                      <span>项目经理</span>
                    </template>
                    {{ detail.project_manager || '-' }}
                  </a-descriptions-item>
                  <a-descriptions-item>
                    <template #label>
                      <a-icon type="user" />
                      <span>承建经理</span>
                    </template>
                    {{ detail.construction_manager || '-' }}
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>
          </a-col>
          <!-- 业务性能 -->
          <a-col :md="24" :xl="12" style="margin-bottom: 16px">
            <div class="servicr-performance-container module-container">
              <div class="module-header" style="margin-bottom: 24px">
                业务性能
              </div>
              <a
                @click="$refs.performanceMoreModal.show(detail, sites)"
                class="container-header-btn"
              >
                更多>>
              </a>
              <a-col
                :span="11"
                class="container-context container-context-left"
              >
                <div class="context-title">平均响应成功率</div>
                <performance-left-chart
                  :chartData="statistic.requestRate"
                ></performance-left-chart>
                <div class="context-tooltip-container">
                  <div>
                    <a-icon type="check-circle" />
                    响应成功：{{ statistic.requestsSuccess }} 次
                  </div>
                  <div>
                    <a-icon type="close-circle" />
                    响应失败：{{ statistic.requestsFailed }} 次
                  </div>
                </div>
              </a-col>
              <a-col
                :span="13"
                class="container-context container-context-right"
              >
                <div class="context-title">请求统计</div>
                <div class="count-container">
                  <div style="margin-right: 24px">
                    <p>请求数</p>
                    <p>{{ statistic.requests }}</p>
                  </div>
                  <div>
                    <p>P95</p>
                    <p>{{ statistic['95th_request_time'] }}</p>
                  </div>
                  <!-- <div :class="isNegativeNumber ? 'green-border-color' : ''">
                    <p>环比</p>
                    <p :class="isNegativeNumber ? 'green-color' : 'red-color'">
                      <up-icon
                        v-if="!isNegativeNumber"
                        style="
                          font-size: 16px;
                          vertical-align: initial;
                          margin-left: -1px;
                          margin-right: -2px;
                        "
                      ></up-icon>
                      <down-icon
                        v-else
                        style="
                          font-size: 16px;
                          vertical-align: initial;
                          margin-left: -1px;
                          margin-right: -2px;
                          color: #52c41a;
                        "
                      ></down-icon>
                      {{ requestCompareCount }}%
                    </p>
                  </div> -->
                </div>
                <performance-right-chart
                  v-if="sites.length"
                  :serviceId="detail.id"
                  @rate="
                    v => {
                      if (v >= 0) {
                        requestCompareCount = v
                      } else {
                        isNegativeNumber = true
                        requestCompareCount = v.toString().split('-')[1]
                      }
                    }
                  "
                  :sites="sites"
                ></performance-right-chart>
              </a-col>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="alerts" tab="历史告警">
        <div
          style="
            background: #fff;
            border-radius: 8px;
            padding: 24px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
            position: relative;
          "
        >
          <alert-history-table></alert-history-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="event" tab="历史事件">
        <a-card :bordered="false" style="margin-bottom: 16px">
          <event-history-table
            :get-table-data-func="getTableDataFunc"
            :fetch-id="detail.id"
            :tools-list="['reload', 'search', 'leftTool', 'setting']"
          ></event-history-table>
        </a-card>
      </a-tab-pane>
    </a-tabs>
    <performance-more-modal ref="performanceMoreModal"></performance-more-modal>
  </div>
</template>

<script>
import { alertSeverities } from '@/utils/const'
import {
  getService,
  getServiceAlertCount,
  getServiceEventList,
  getServiceStatistic,
  getServiceSiteList
} from '@/api/service'
// import DownIcon from '@/components/icon/DownIcon'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
// import UpIcon from '@/components/icon/UpIcon'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import SimpleSelect from '@/components/select/SimpleSelect'
import EventHistoryTable from '@/components/table/EventHistoryTable.vue'
import AlertChart from './modules/AlertChart'
import AlertHistoryTable from './modules/AlertHistoryTable.vue'
// import LogStatisticsTable from './modules/LogStatisticsTable.vue'
import PerformanceLeftChart from './modules/PerformanceLeftChart'
import PerformanceMoreModal from './modules/PerformanceMoreModal.vue'
import PerformanceRightChart from './modules/PerformanceRightChart'
import ServiceTopology from './modules/ServiceTopology'
import SourceCountCard from './modules/SourceCountCard'

export default {
  name: 'ServiceDashboardDetail',
  components: {
    AlertChart,
    AlertHistoryTable,
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    EventHistoryTable,
    // LogStatisticsTable,
    SeverityRadioGroup,
    SourceCountCard,
    SimpleSelect,
    StatusQuestionIcon,
    ServiceTopology,
    PerformanceLeftChart,
    // UpIcon,
    PerformanceRightChart,
    // DownIcon,
    PerformanceMoreModal
  },
  data () {
    return {
      getTableDataFunc: getServiceEventList,
      activeTab: 'overview',
      currentSeverity: 'all',
      alertStatisticsSeverity: 'all',
      alertCountSeverity: 'all',
      currentAlertCount: 0,
      serviceId: this.$route.params.id,
      severityOptions: [
        { label: '全部', value: 'all' },
        ...alertSeverities.map(item => {
          return { label: this.$t(`alert_severity.${item}`), value: item }
        })
      ],
      relevanceSourceType: [
        'application',
        'site',
        'ssl_certificate',
        'middleware',
        'database',
        'os'
      ],
      detail: {
        id: '',
        name: '',
        description: '',
        created_at: '',
        updated_at: '',
        topology: []
      },
      alertCountData: [],
      statistic: {
        requests: 0,
        requestsFailed: 0,
        requestsSuccess: 0,
        requestRate: [{ value: 0 }],
        '95th_request_time': 0.0
      },
      requestCompareCount: 0,
      sites: [],
      isNegativeNumber: false
    }
  },
  methods: {
    fetch () {
      getService(this.serviceId).then(res => {
        this.detail = res.data
      })
      getServiceStatistic(this.serviceId).then(res => {
        const data = res.data
        this.statistic.requests = data.requests
        this.statistic.requestsFailed = data.requests_failed
        this.statistic.requestsSuccess = data.requests - data.requests_failed
        if (data.requests === 0) {
          this.statistic.requestRate[0].value = 0
        } else {
          this.statistic.requestRate[0].value =
            parseInt((this.statistic.requestsSuccess / data.requests) * 10000) /
            100
        }
        this.statistic['95th_request_time'] = data['95th_request_time']
      })
      getServiceSiteList(this.serviceId).then(res => {
        this.sites = res.data.data
      })
    },
    fetchAlertCount () {
      const params = { count_of: 'recovered' }
      if (this.alertCountSeverity && this.alertCountSeverity !== 'all') {
        params.severity = this.alertCountSeverity
      }
      getServiceAlertCount(this.serviceId, params).then(res => {
        this.alertCountData = res.data.data.map(item => {
          return {
            name: item.name === 'recovered' ? '已恢复告警' : '未恢复告警',
            value: item.value
          }
        })
      })
    }
  },
  mounted () {
    this.fetch()
    this.fetchAlertCount()
  },
  watch: {
    alertCountSeverity (v) {
      this.fetchAlertCount()
    }
  }
}
</script>

<style lang="less">
#service-dashboard-detail-container {
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  }

  .ant-col-24 .module-container .ant-btn-link {
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      color: #096dd9;
    }
  }

  .relevance-source-container {
    height: 340px;
  }

  .current-alert-container {
    height: 340px;
  }

  .service-topology-container {
    height: 620px;

    .ant-btn {
      font-size: 12px;
      font-weight: 400;
      color: #096dd9;
      line-height: 28px;
      width: 80px;
      height: 28px;
      background: rgba(9, 109, 217, 0.08);
      border: 1px solid rgba(9, 109, 217, 0.31);
      border-radius: 16px;
    }
    .ant-btn:nth-of-type(2) {
      background: #ffac051c;
      border: 1px solid #f6a90f;
      border-radius: 16px;
    }
  }

  .alert-statistics-container {
    height: 260px;
  }

  .service-info-container {
    height: 260px;

    .service-info {
      height: 196px;
      background: #f5f9fd;
      border-radius: 8px;
      padding: 16px 20px;

      .header-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }

      .header-description {
        font-size: 12px;
        color: #096dd9;
        line-height: 20px;
        font-weight: 400;
      }

      .ant-descriptions-item-label {
        color: rgba(0, 0, 0, 0.5);
        i {
          font-size: 16px;
          margin-right: 8px;
          color: #0f70da;
          vertical-align: text-bottom;
        }
      }

      .ant-descriptions-item-content {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    p {
      margin: 0;
    }
  }

  .servicr-performance-container {
    height: 344px;

    .container-header-btn {
      position: absolute;
      top: 20px;
      right: 18px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #096dd9;
      line-height: 17px;
    }

    .container-context {
      .context-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
      }
      .context-tooltip-container {
        height: 20px;
        display: flex;
        justify-content: space-evenly;
        padding-right: 20px;
        flex-wrap: wrap;

        div {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 17px;

          i {
            margin-right: 5px;
            color: #5ac725;
          }

          &:nth-of-type(2) {
            i {
              color: #ff4d4f;
            }
          }
        }
      }
    }
    .container-context-left {
      border-right: 0.5px solid #e0e0e0;
      padding-left: 16px !important;
    }
    .container-context-right {
      border-left: 0.5px solid #e0e0e0;
      padding-left: 40px !important;

      .count-container {
        overflow: hidden;
        margin-top: 9px;
        p {
          margin: 0;
        }

        & > div {
          float: left;
          height: 64px;
          border-radius: 8px;
          border: 1px solid #c6ddf6;
          padding: 9px 16px 8px 12px;
          min-width: 90px;

          p {
            &:nth-of-type(1) {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 17px;
            }

            &:nth-of-type(2) {
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #096dd9;
              line-height: 30px;
            }
          }
          .red-color {
            color: #ff4d4f !important;
          }
          .green-color {
            color: #52c41a !important;
          }
        }
        // & > div:nth-of-type(2) {
        //   border-color: #ff4d4f !important;
        // }

        & > div.green-border-color {
          border-color: #52c41a !important;
        }
      }
    }
  }
}
</style>
