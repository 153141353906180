<template>
  <div id="service-topology">
    <div id="topology"></div>
  </div>
</template>

<script>
import { Graph } from '@antv/x6'
// import { blueColor } from '@/utils/const'

let graph = null

export default {
  name: 'ServiceTopology',
  props: {
    cells: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.initGraph()
    graph.fromJSON({ cells: this.formatterData(this.cells) })
    graph.scaleContentToFit({ maxScale: 1, minScale: 0.7 })
    graph.centerContent()

    // this.$nextTick(() => {
    //   const container = document.getElementById('topology')
    //   graph = new Graph({
    //     container,
    //     autoResize: false,
    //     connecting: {
    //       allowBlank: false,
    //       allowMulti: 'withPort',
    //       allowLoop: false,
    //       allowNode: false,
    //       // router: 'manhattan',
    //       // connector: 'rounded',
    //       createEdge () {
    //         return new Shape.Edge({
    //           attrs: {
    //             line: {
    //               stroke: blueColor,
    //               strokeDasharray: 1,
    //               targetMarker: null,
    //               style: {
    //                 animation: 'topology-edge 60s infinite linear'
    //               }
    //             }
    //           }
    //         })
    //       }
    //     },
    //     embedding: {
    //       enabled: true,
    //       findParent ({ node }) {
    //         return this.getNodes().filter(n => {
    //           if (n.shape === 'group' && !n.collapsed) {
    //             const targetBBox = n.getBBox()
    //             return node.getBBox().isIntersectWithRect(targetBBox)
    //           }
    //           return false
    //         })
    //       }
    //     },
    //     // grid: false,
    //     // history: true,
    //     interacting: false,
    //     // keyboard: true,
    //     // resizing: {
    //     //   enabled: node => {
    //     //     return node.shape !== 'vue-shape'
    //     //   }
    //     // },
    //     // selecting: {
    //     //   enabled: true,
    //     //   rubberband: true,
    //     //   showNodeSelectionBox: true
    //     // },
    //     // scroller: false,
    //     panning: true
    //   })
    //   graph.on('node:collapse', ({ e, node, view }) => {
    //     node.toggleCollapse()
    //     const collapsed = node.collapsed
    //     const cells = node.getDescendants()
    //     cells.forEach(cell => {
    //       if (collapsed) {
    //         cell.hide()
    //       } else {
    //         cell.show()
    //       }
    //     })
    //   })

    //   // graph.on('edge:mouseenter', ({ edge }) => {
    //   //   const sourceNode = edge.getSourceNode()
    //   //   sourceNode.setAttrs({
    //   //     body: {
    //   //       stroke: '#999999',
    //   //       strokeWidth: 1,
    //   //       strokeDasharray: 5,
    //   //       fill: '#99999950'
    //   //     }
    //   //   })
    //   //   const targetNode = edge.getTargetNode()
    //   //   targetNode.setAttrs({
    //   //     body: {
    //   //       stroke: '#999999',
    //   //       strokeWidth: 1,
    //   //       fill: '#99999950',
    //   //       strokeDasharray: 5
    //   //     }
    //   //   })
    //   //   edge.attr('line/stroke', '#999999')
    //   //   edge.attr('line/strokeWidth', 1)
    //   //   edge.attr('line/strokeDasharray', 0)
    //   // })

    //   // graph.on('edge:mouseleave', ({ edge }) => {
    //   //   const sourceNode = edge.getSourceNode()
    //   //   sourceNode.setAttrs({
    //   //     body: {
    //   //       strokeWidth: 0,
    //   //       fill: 'none'
    //   //     }
    //   //   })
    //   //   const targetNode = edge.getTargetNode()
    //   //   targetNode.setAttrs({
    //   //     body: {
    //   //       strokeWidth: 0,
    //   //       fill: 'none'
    //   //     }
    //   //   })
    //   //   edge.attr('line/stroke', '#999')
    //   //   edge.attr('line/strokeWidth', 0.5)
    //   //   edge.attr('line/strokeDasharray', 5)
    //   // })

    //   this.cells.forEach(item => {
    //     if (item.shape === 'edge') {
    //       item.attrs.line.stroke = '#999'
    //       item.attrs.line.strokeWidth = 0.5
    //       item.attrs.line.targetMarker = null
    //     }
    //     if (item.shape === 'group') {
    //       item.attrs.button.fill = '#1890ff90'
    //     }
    //     if (item.shape === 'vue-shape') {
    //       item.size = { height: 48, width: 48 }
    //       if (Array.isArray(item.ports)) {
    //         const ports = item.ports
    //         item.ports = {
    //           groups: {
    //             default: {
    //               markup: [
    //                 {
    //                   tagName: 'rect',
    //                   selector: 'rect'
    //                 }
    //               ],
    //               attrs: {
    //                 rect: {
    //                   magnet: true,
    //                   stroke: '#31d0c6',
    //                   fill: 'rgba(255,255,255,0)',
    //                   strokeWidth: 0,
    //                   width: 48,
    //                   height: 48,
    //                   y: -24
    //                 }
    //               }
    //             }
    //           },
    //           items: []
    //         }

    //         ports.forEach(port => {
    //           port.group = 'default'
    //         })
    //         item.ports.items = ports
    //       }
    //     }
    //   })
    //   graph.fromJSON({ cells: this.cells })
    //   graph.centerContent()
    // })
  },
  methods: {
    initGraph () {
      const container = document.getElementById('topology')
      graph = new Graph({
        container,
        autoResize: true,
        grid: false,
        scroller: false,
        keyboard: false,
        panning: true,
        interacting: false,
        connecting: {
          validateMagnet ({ e, magnet, view, cell }) {
            return false
          }
        }
      })
      graph.on('node:collapse', ({ e, node, view }) => {
        node.toggleCollapse()
        const collapsed = node.collapsed
        const cells = node.getDescendants()
        cells.forEach(cell => {
          if (collapsed) {
            cell.hide()
          } else {
            cell.show()
          }
        })
      })
    },
    formatterData (v) {
      v.forEach(item => {
        if (item.shape === 'edge') {
          item.attrs.line.stroke = '#999'
          item.attrs.line.strokeWidth = 0.5
          item.attrs.line.targetMarker = null
        }
        if (item.shape === 'group') {
          item.attrs.button.fill = '#1890ff90'
        }
        if (item.shape === 'vue-shape') {
          item.size = { height: 48, width: 48 }
          if (Array.isArray(item.ports)) {
            const ports = item.ports
            item.ports = {
              groups: {
                default: {
                  markup: [
                    {
                      tagName: 'rect',
                      selector: 'rect'
                    }
                  ],
                  attrs: {
                    rect: {
                      magnet: true,
                      stroke: '#31d0c6',
                      fill: 'rgba(255,255,255,0)',
                      strokeWidth: 0,
                      width: 48,
                      height: 48,
                      y: -24
                    }
                  }
                }
              },
              items: []
            }

            ports.forEach(port => {
              port.group = 'default'
            })
            item.ports.items = ports
          }
        }
      })
      return v
    }
  },
  watch: {
    cells (v) {
      graph.fromJSON({ cells: this.formatterData(v) })
      graph.scaleContentToFit({ maxScale: 1, minScale: 0.7 })
      graph.centerContent()
    }
  }
}
</script>

<style lang="less">
#service-topology {
  min-height: 520px;
  height: 520px;
  margin: 50px 16px 0;
  display: flex;
  overflow: hidden;
  #topology {
  min-height: 520px;
  flex: 1;

  .x6-port-default {
      rect {
        cursor: default;
      }
    }
  }
}
</style>
