<template>
  <a-radio-group
    id="time-radio-button"
    button-style="solid"
    size="small"
    :value="value"
    @change="e => $emit('input', e.target.value)"
    style="font-size: 12px"
  >
    <a-radio-button
      v-for="item in options"
      :key="item.value"
      :value="item.value"
    >
      {{ item.label }}
    </a-radio-button>
  </a-radio-group>
</template>

<script>
export default {
  name: 'TimeRadio',
  props: {
    value: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      options: [
        { label: '5分钟', value: '0.12' },
        { label: '15分钟', value: '0.4' },
        { label: '1小时', value: '1' },
        { label: '3小时', value: '3' },
        { label: '6小时', value: '6' },
        { label: '1天', value: '24' }
      ]
    }
  }
}
</script>
<style lang="less">
#time-radio-button {
  // width: 308px;
  height: 24px;

  .ant-radio-button-wrapper {
    width: 52px;
    font-size: 12px;
    padding: 0;
    text-align: center;
  }
}
</style>
