<template>
  <a-modal
    id="source-table-modal-container"
    :visible="visible"
    :title="title"
    :width="1160"
    :footer="false"
    :destroy-on-close="true"
    @cancel="handleCancel"
  >
    <div>
      <span
        style="
          margin-left: 18px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 20px;
        "
      >
        状态：
      </span>
      <simple-select
        :options="statusOptions"
        v-model="currentStatus"
      ></simple-select>
      <span
        style="
          margin-left: 56px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 20px;
        "
      >
        资源：
      </span>
      <a-input
        v-model="searchText"
        style="width: 170px; height: 32px"
        placeholder="请输入查询关键字"
      ></a-input>
      <span
        style="
          margin-left: 56px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 20px;
        "
      >
        IP 地址：
      </span>
      <a-input
        v-model="searchIp"
        style="width: 170px; height: 32px"
        placeholder="请输入IP地址"
      ></a-input>

      <a-button @click="reset" style="float: right; margin: 0 16px 0 24px"
        >重置</a-button
      >
      <a-button @click="search" type="primary" style="float: right"
        >查询</a-button
      >
    </div>

    <my-os-table
      v-if="sourceType === 'os'"
      style="margin: 36px 16px 0"
      :tableParams="tableParams"
      :serviceId="serviceId"
      :getFunc="osGetFunc"
    ></my-os-table>
    <my-database-table
      v-else-if="sourceType === 'database'"
      style="margin: 36px 16px 0"
      :tableParams="tableParams"
      :serviceId="serviceId"
      :getFunc="databaseGetFunc"
    ></my-database-table>
    <my-middleware-table
      v-else-if="sourceType === 'middleware'"
      style="margin: 36px 16px 0"
      :tableParams="tableParams"
      :serviceId="serviceId"
      :getFunc="middlewareGetFunc"
    ></my-middleware-table>
    <my-application-table
      v-else-if="sourceType === 'application'"
      style="margin: 36px 16px 0"
      :tableParams="tableParams"
      :serviceId="serviceId"
      :getFunc="applicationGetFunc"
    ></my-application-table>

    <my-site-table
      v-else-if="sourceType === 'site'"
      style="margin: 36px 16px 0"
      :tableParams="tableParams"
      :serviceId="serviceId"
      :getFunc="siteGetFunc"
    ></my-site-table>
    <my-ssl-certificate-table
      v-else-if="sourceType === 'ssl_certificate'"
      style="margin: 36px 16px 0"
      :tableParams="tableParams"
      :serviceId="serviceId"
      :getFunc="SSLCertificateGetFunc"
    ></my-ssl-certificate-table>
  </a-modal>
</template>

<script>
import {
  getServiceDatabaseList,
  getServiceMiddlewareList,
  getServiceOSList,
  getServiceApplicationList,
  getServiceSiteList,
  getServiceSSLCertificateList
} from '@/api/service.js'
import { sourceStatuses } from '@/utils/const'
import SimpleSelect from '@/components/select/SimpleSelect'
import MyOSTable from '@/components/table/MyOSTable'
import MyApplicationTable from '@/components/table/MyApplicationTable'
import MySiteTable from '@/components/table/MySiteTable'
import MyMiddlewareTable from '@/components/table/MyMiddlewareTable'
import MyDatabaseTable from '@/components/table/MyDatabaseTable'
import MySSLCertificateTable from '@/components/table/MySSLCertificateTable'

export default {
  name: 'SourceTableModal',
  props: {
    title: {
      type: String
    }
  },
  components: {
    SimpleSelect,
    'my-os-table': MyOSTable,
    MyApplicationTable,
    MySiteTable,
    MyMiddlewareTable,
    MyDatabaseTable,
    'my-ssl-certificate-table': MySSLCertificateTable
  },
  data () {
    return {
      osGetFunc: getServiceOSList,
      middlewareGetFunc: getServiceMiddlewareList,
      databaseGetFunc: getServiceDatabaseList,
      applicationGetFunc: getServiceApplicationList,
      siteGetFunc: getServiceSiteList,
      SSLCertificateGetFunc: getServiceSSLCertificateList,
      sourceType: '',
      visible: false,
      currentStatus: { label: '全部', value: 'all' },
      customStatusValue: undefined,
      searchText: '',
      searchIp: '',
      statusOptions: [
        { label: '全部', value: 'all' },
        ...sourceStatuses.map(item => {
          return { label: this.$t(`source_status.${item}`), value: item }
        })
      ],
      tableParams: {},
      serviceId: ''
    }
  },
  methods: {
    show (type, serviceId, params) {
      this.serviceId = serviceId
      this.sourceType = type
      if (params.value) {
        this.currentStatus = { ...params }
        this.tableParams = { status: params.value }
      }
      this.visible = true
    },
    handleCancel () {
      this.currentStatus = { label: '全部', value: 'all' }
      this.customStatusValue = undefined
      this.searchText = ''
      this.tableParams = {}
      this.visible = false
    },
    reset () {
      this.currentStatus = { label: '全部', value: 'all' }
      this.searchText = ''
      this.searchIp = ''
      this.search()
    },
    search () {
      const params = {}
      if (this.currentStatus.value !== 'all') { params.status = this.currentStatus.value }
      if (this.searchText) params.name = this.searchText
      if (this.searchIp) params.monitor_address = this.searchIp
      this.tableParams = { ...params }
    }
  }
}
</script>

<style lang="less">
#source-table-modal-container {
  // height: 840px;

  .ant-table-content {
    .ant-table-placeholder {
      border: none;
    }
    .ant-table-thead > tr > th {
      background: #e6f0fb !important;
      height: 40px !important;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      border: none;
      border-radius: 0;
    }
    .ant-table-tbody > tr {
      background: #fff;
      height: 56px;
      &:nth-of-type(2n) {
        background: #f5f9fe;
      }
      & > td {
        border: none !important;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-pagination {
      .ant-pagination-item a,
      i {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: rgba(0, 0, 0, 0.88);
      }

      .ant-pagination-total-text {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: rgba(0, 0, 0, 0.5);
        line-height: 32px;
        margin-right: 16px;
      }
    }
  }
}
</style>
