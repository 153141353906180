<template>
  <div>
    <list-layout
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :get-table-data-func="getTableDataFunc"
      :customer-params="params"
      :fetch-id="id"
      :refresh="refresh"
      :table-operation-width="200"
      :defaultSelected="tableSelectData"
      @tableSelectData="(v) => tableSelectData = v"
      :tools-list="['setting', 'reload', 'search', 'leftTool']"
      :can-drag-column-width="true"
    >
      <template #leftTool>
        <datetime-range
          @ok="
            v => {
              datetimeRange = v
            }
          "
        ></datetime-range>
      </template>

      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a @click="$refs.detailDrawer.show(slotProps.record.id)">
            {{ slotProps.text }}
          </a>
        </a-tooltip>
      </template>

      <template #severity="{ slotProps }">
        <alert-severity-tag :severity="slotProps.text"></alert-severity-tag>
      </template>

      <template #sourceType="{ slotProps }">
        {{ $t(`source_type.${slotProps.text}`) }}
      </template>

      <template #source="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text?.name">
          <a @click="click(slotProps.record.source_type, slotProps.text?.id)">
            {{ slotProps.text?.name }}
          </a>
        </a-tooltip>
      </template>

      <template #duration="{ slotProps }">
        {{
          duration(
            slotProps.record.triggered_at,
            slotProps.record.recovered_at
          )
        }}
      </template>

      <template #recovered="{ slotProps }">
        <boolean-tag
          :value="slotProps.text"
          false-name="未恢复"
          true-name="已恢复"
        ></boolean-tag>
      </template>

      <template #operation="{ slotProps }">
        <delete-button @confirm="confirm(slotProps.record.id)"></delete-button>
      </template>

    </list-layout>
    <alert-drawer ref="detailDrawer"></alert-drawer>
    <network-device-drawer ref="networkDeviceDrawer"></network-device-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <os-drawer ref="osDrawer"></os-drawer>
    <middleware-drawer ref="middlewareDrawer"></middleware-drawer>
    <database-drawer ref="databaseDrawer"></database-drawer>
    <application-drawer ref="applicationDrawer"></application-drawer>
    <site-drawer ref="siteDrawer"></site-drawer>
    <containerization-drawer ref="containerizationDrawer" :containerization-type="containerizationType"></containerization-drawer>
  </div>
</template>

<script>
import { deleteAlert } from '@/api/alert'
import { getContainerization } from '@/api/containerization'
import { getServiceAlertList } from '@/api/service'
import { createAlertITSM } from '@/api/itsm'
import DatetimeRange from '@/components/DatetimeRange.vue'
import ListLayout from '@/components/ListLayout.vue'
import AlertDrawer from '@/components/drawer/AlertDrawer/index.vue'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag.vue'
import BooleanTag from '@/components/tag/BooleanTag.vue'
import DeleteButton from '@/components/button/DeleteButton.vue'
import { humanAlertDuration } from '@/utils'
import { alertSeverities, sourceTypes } from '@/utils/const'

export default {
  name: 'AlertHistoryTable',
  components: {
    DatetimeRange,
    ListLayout,
    AlertDrawer,
    AlertSeverityTag,
    BooleanTag,
    DeleteButton,
    ApplicationDrawer: () => import('@/components/drawer/ApplicationDrawer.vue'),
    DatabaseDrawer: () => import('@/components/drawer/DatabaseDrawer/index.vue'),
    MiddlewareDrawer: () => import('@/components/drawer/MiddlewareDrawer/index.vue'),
    NetworkDeviceDrawer: () =>
      import('@/components/drawer/NetworkDeviceDrawer.vue'),
    'os-drawer': () => import('@/components/drawer/OSDrawer/index.vue'),
    ServerDrawer: () => import('@/components/drawer/ServerDrawer/index.vue'),
    SiteDrawer: () => import('@/components/drawer/SiteDrawer.vue'),
    StorageDrawer: () => import('@/components/drawer/StorageDrawer/index.vue'),
    ContainerizationDrawer: () => import('@/components/drawer/ContainerizationDrawer.vue')
  },
  data () {
    return {
      id: this.$route.params.id,
      refresh: false,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 300,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'severity',
          title: '严重性',
          width: 90,
          scopedSlots: {
            customRender: 'severity'
          },
          filteredValue: [],
          filters: alertSeverities.map(value => {
            return {
              text: this.$t(`alert_severity.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'source_type',
          title: '资源类型',
          width: 140,
          scopedSlots: {
            customRender: 'sourceType'
          },
          filteredValue: [],
          filters: sourceTypes.map(value => {
            return {
              text: this.$t(`source_type.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'source',
          title: '资源名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'source'
          }
        },
        {
          dataIndex: 'triggered_at',
          title: '触发时间',
          width: 200,
          scopedSlots: {
            customRender: 'triggeredAt'
          }
        },
        {
          dataIndex: 'recovered_at',
          title: '恢复时间',
          width: 200,
          scopedSlots: {
            customRender: 'recoveredAt'
          }
        },
        {
          dataIndex: 'duration',
          title: '持续时间',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'duration'
          }
        },
        {
          dataIndex: 'recovered',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'recovered'
          },
          filteredValue: [],
          filters: [
            {
              text: '已恢复',
              value: 'true'
            },
            {
              text: '未恢复',
              value: 'false'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'severity',
        'source_type',
        'source',
        'triggered_at',
        'duration',
        'recovered'
      ],
      getTableDataFunc: getServiceAlertList,
      datetimeRange: [],
      tableSelectData: [],
      customerParams: {},
      containerizationType: ''
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.customerParams) {
        if (Object.hasOwnProperty.call(this.customerParams, key)) {
          obj[key] = this.customerParams[key]
        }
      }
      if (this.datetimeRange.length) {
        obj.datetime_from = this.datetimeRange[0].format('YYYY-MM-DD HH:mm')
        obj.datetime_to = this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }
      return obj
    }
  },
  methods: {
    click (sourceType, sourceId) {
      switch (sourceType) {
        case 'network_device':
          this.$refs.networkDeviceDrawer.show(sourceId)
          break
        case 'storage':
          this.$refs.storageDrawer.show(sourceId)
          break
        case 'server':
          this.$refs.serverDrawer.show(sourceId)
          break
        case 'os':
          this.$refs.osDrawer.show(sourceId)
          break
        case 'middleware':
          this.$refs.middlewareDrawer.show(sourceId)
          break
        case 'database':
          this.$refs.databaseDrawer.show(sourceId)
          break
        case 'application':
          this.$refs.applicationDrawer.show(sourceId)
          break
        case 'containerization':
          getContainerization(sourceId).then(res => {
            this.containerizationType = res.data.containerization_type
            this.$refs.containerizationDrawer.show(sourceId)
          })
          break
        case 'site':
          this.$refs.siteDrawer.show(sourceId)
          break
      }
    },
    duration (triggeredAt, recoveredAt) {
      return humanAlertDuration(triggeredAt, recoveredAt)
    },
    createTicket (id) {
      createAlertITSM({ alert_id: id }).then(res => {
        this.$message.success(res.message)
        this.refresh = !this.refresh
      })
    },
    async confirm (id = '') {
      if (id) {
        await deleteAlert(id).then(res => {
          this.$message.success(res.message)
        })
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
