<template>
  <div class="performance-right-chart-container">
    <div>
      当前
      <div id="performance-current-chart"></div>
    </div>
    <div>
      同比
      <div id="performance-compare-chart"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Chart } from '@antv/g2'
import { getSiteMonitorHistory } from '@/api/site'
import { humanizeValue } from '@/utils'

let chartTop = null
let chartBottom = null

export default {
  name: 'PerformanceRightChart',
  props: {
    sites: {
      type: Array
    },
    serviceId: {
      type: String
    }
  },
  data () {
    return {
      currentDate: [moment().subtract(0.5, 'hour'), moment()],
      compareDate: [moment().subtract(24.5, 'hour'), moment().subtract(24, 'hour')],
      lastWeekDate: [moment().subtract(6, 'day').subtract(0.5, 'hour'), moment().subtract(6, 'day')],
      currentData: [],
      compareData: []
    }
  },
  mounted () {
    this.fetch()
    this.initTop()
    this.initBottom()
  },
  methods: {
    fetch () {
      if (this.sites.length) {
        getSiteMonitorHistory(this.sites[0].id, {
          key: `log.nginx[${this.sites[0].url},requests]`,
          datetime_from: this.currentDate[0].format('YYYY-MM-DD HH:mm'),
          datetime_to: this.currentDate[1].format('YYYY-MM-DD HH:mm')
        }).then(res => {
          const data = res.data
          this.currentData = data.data

          if (data.total) {
            getSiteMonitorHistory(this.sites[0].id, {
              key: `log.nginx[${this.sites[0].url},requests]`,
              datetime_from: this.lastWeekDate[0].format('YYYY-MM-DD HH:mm'),
              datetime_to: this.lastWeekDate[1].format('YYYY-MM-DD HH:mm')
            }).then(res => {
              const data = res.data
              const a = this.currentData[this.currentData.length - 1].value * 1 || 0
              let b = 0
              if (data.total) b = data.data[data.data.length - 1].value * 1 || 0
              let rate = 0
              if (b !== 0) rate = parseInt((a - b) / b * 100)

              this.$emit('rate', rate)
            })
          }
        })
        getSiteMonitorHistory(this.sites[0].id, {
          key: `log.nginx[${this.sites[0].url},requests]`,
          datetime_from: this.compareDate[0].format('YYYY-MM-DD HH:mm'),
          datetime_to: this.compareDate[1].format('YYYY-MM-DD HH:mm')
        }).then(res => {
          this.compareData = res.data.data
        })
      }
    },
    initTop () {
      chartTop = new Chart({
        container: 'performance-current-chart',
        autoFit: true,
        appendPadding: [12, 0, 0, 0]
      })
      chartTop.axis(false)
      chartTop.scale({
        timestamp: {
          type: 'time',
          mask: 'MM-DD HH:mm'
        },
        value: {
          min: 0,
          nice: true,
          alias: '值',
          formatter: value => humanizeValue(value)
        }
      })
      chartTop
        .line()
        .position('timestamp*value')
        .color('#195EFF')
      chartTop.render()
    },
    renderCurrent () {
      if (this.currentData.length === 0) {
        chartTop.changeVisible(false)
      } else {
        let max = this.currentData[0]
        for (const datum of this.currentData) {
          if (datum.value > max.value) max = datum
        }
        chartTop.annotation().dataMarker({
          point: {
            style: {
              stroke: '#195EFF'
            }
          },
          line: {
            length: 0
          },
          top: true,
          position: [max.timestamp, max.value],
          text: {
            content: `峰值: ${humanizeValue(max.value)}`
          }
        })
        chartTop.changeData(this.currentData)
        chartTop.changeVisible(true)
      }
    },
    initBottom () {
      chartBottom = new Chart({
        container: 'performance-compare-chart',
        autoFit: true,
        appendPadding: [12, 0, 0, 0]
      })
      chartBottom.axis(false)
      chartBottom.scale({
        timestamp: {
          type: 'time',
          mask: 'MM-DD HH:mm'
        },
        value: {
          min: 0,
          nice: true,
          alias: '值',
          formatter: value => humanizeValue(value)
        }
      })
      chartBottom
        .line()
        .position('timestamp*value')
        .color('#52C41A')
      chartBottom.render()
    },
    renderCompare () {
      if (this.compareData.length === 0) {
        chartBottom.changeVisible(false)
      } else {
        let max = this.compareData[0]
        for (const datum of this.compareData) {
          if (datum.value > max.value) max = datum
        }
        chartBottom.annotation().dataMarker({
          point: {
            style: {
              stroke: '#52C41A'

            }
          },
          top: true,
          line: {
            length: 0
          },
          position: [max.timestamp, max.value],
          text: {
            content: `峰值: ${humanizeValue(max.value)}`
          }
        })
        chartBottom.changeData(this.compareData)
        chartBottom.changeVisible(true)
      }
    }
  },
  watch: {
    currentData () {
      this.renderCurrent()
    },
    compareData () {
      this.renderCompare()
    }
  }
}
</script>

<style lang="less">
.performance-right-chart-container {
  height: 170px;
  margin-top: 12px;

  #performance-current-chart{
    // min-width: 326px;
    height: 60px;
    display: inline-block;
    margin-left: 12px;
    width: calc(100% - 44px);
  }

  #performance-compare-chart {
    // min-width: 326px;
    width: calc(100% - 44px);
    height: 60px;
    display: inline-block;
    margin-left: 12px;
    margin-top: 24px;
  }
}
</style>
