<template>
  <a-modal
    id="performance-more-modal"
    :destroy-on-close="true"
    :title="`${detail.name} 业务性能`"
    :visible="visible"
    :width="900"
    :footer="null"
    @cancel="visible = false"
    @ok="handleOk">

    <div class="operation-container">
      <div style="position: absolute; left: 0; font-size: 12px; z-index: 9;">
        常用时段
        <time-radio style="margin-left: 16px;" v-model="time"></time-radio>
      </div>
      <div style="position: absolute; right: 0; font-size: 12px; z-index: 9;">
        自定义时段
        <datetime-range
          style="width: 286px; margin-left: 16px;"
          :allow-clear="false"
          :default-value="datetimeRange"
          @ok="
            v => {
              datetimeRange = v
            }
          "
        ></datetime-range>
      </div>
      <div style="position: absolute; left: 0; bottom: 36px; z-index: 9;">
        <request-type-radio @change=" v => requestType = v" :options="requestTypeData"></request-type-radio>
      </div>
      <div style="position: absolute; left: 0; bottom: 0; z-index: 9; text-align: center; width: 100%;">
        <a-checkbox v-model="compareYesterday">
          <question-icon title="环比" description="昨天同时段"></question-icon>
        </a-checkbox>
        <a-checkbox v-model="compareLastWeek">
          <question-icon title="同比" description="上周同时段"></question-icon>
        </a-checkbox>
      </div>
    </div>
    <multi-line-chart
      v-if="requestType && sites.length"
      :itemKey="requestType"
      chart-id="performance-more-chart"
      :history-func="historyFunc"
      :source-id="sites[0].id"
      :datetime-range="datetimeRange"
      :compare-last-week="compareLastWeek"
      :compare-yesterday="compareYesterday"
    ></multi-line-chart>
    <a-empty v-else style="margin: 40px;"></a-empty>
  </a-modal>
</template>

<script>
import moment from 'moment'
import TimeRadio from '@/components/radio/TimeRadio.vue'
import DatetimeRange from '@/components/DatetimeRange.vue'
import RequestTypeRadio from '@/components/radio/RequestTypeRadio.vue'
import MultiLineChart from '@/components/chart/MultiLineChart.vue'
import { getSiteMonitorHistory } from '@/api/site'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'

export default {
  name: 'PerformanceMoreModal',
  components: {
    TimeRadio,
    DatetimeRange,
    RequestTypeRadio,
    MultiLineChart,
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: ''
      },
      requestType: '',
      time: '1',
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      requestTypeData: [],
      historyFunc: getSiteMonitorHistory,
      compareYesterday: false,
      compareLastWeek: false,
      sites: []
    }
  },
  methods: {
    show (detail, sites) {
      this.detail = detail
      this.sites = sites
      if (this.sites && this.sites.length) {
        this.requestTypeData = [
          { label: '请求数', value: `log.nginx[${this.sites[0].url},requests]` },
          { label: '95% 请求时间', value: `log.nginx[${this.sites[0].url},95th_request_time]` },
          { label: '发送字节数', value: `log.nginx[${this.sites[0].url},body_bytes_sent]` },
          { label: '2xx 请求数', value: `log.nginx[${this.sites[0].url},2xx]` },
          { label: '4xx 请求数', value: `log.nginx[${this.sites[0].url},4xx]` },
          { label: '5xx 请求数', value: `log.nginx[${this.sites[0].url},5xx]` }
        ]
        this.requestType = this.requestTypeData[0].value
      }
      this.visible = true
    },
    handleOk () {
      this.visible = false
    }
  },
  watch: {
    time (v) {
      this.datetimeRange = [moment().subtract(v, 'hour'), moment()]
    }
  }
}
</script>

<style lang="less">
#performance-more-modal {
  position: relative;

  .operation-container {
    position: relative;
    width: 100%;
    height: 108px;
    margin-bottom: 24px;
  }
}
</style>
