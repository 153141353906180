<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="name"
      :scroll="{ x: scrollX }"
      :total="total"
      :loading="loading"
    >
      <template slot="status" slot-scope="text">
        <source-status-tag :status="text"></source-status-tag>
      </template>
      <template slot="osType" slot-scope="text">
        {{ $t(`os_type.${text}`) }}
      </template>
      <template slot="operation" slot-scope="text, record">
        <a @click="$refs.detailDrawer.show(record.id)">详情</a>
      </template>
    </a-table>

    <div style="height: 32px; margin-top: 24px;">
      <a-pagination
        :current="fetchParams.page"
        :pageSize="fetchParams.page_size"
        :show-size-changer="false"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="
          page => {
            fetchParams.page = page
            fetch()
          }
        "
        style="float: right"
      ></a-pagination>
    </div>
    <os-drawer ref="detailDrawer"></os-drawer>
  </div>
</template>

<script>
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'MyOSTable',
  components: {
    SourceStatusTag,
    'os-drawer': () => import('@/components/drawer/OSDrawer')
  },
  props: {
    tableParams: {
      type: Object
    },
    serviceId: {
      type: String
    },
    getFunc: {
      type: Function
    }
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          dataIndex: 'status',
          align: 'center',
          title: '状态',
          width: 60,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'os_type',
          title: '系统类型',
          align: 'center',
          width: 160,
          scopedSlots: {
            customRender: 'osType'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          width: 60,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      dataSource: [],
      total: 0,
      fetchParams: {
        page: 1,
        page_size: 10,
        ...this.tableParams
      }
    }
  },
  mounted () {
    window.onresize = () => {
      this.fetch()
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
      window.onresize = null
    }, 300)
  },
  methods: {
    fetch () {
      this.loading = true
      this.getFunc(this.serviceId, this.fetchParams)
        .then(res => {
          this.dataSource = res.data.data
          this.total = res.data.total
        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  watch: {
    tableParams: {
      handler (v) {
        this.fetchParams = {
          page: 1,
          page_size: 10,
          ...v
        }
        this.fetch()
      },
      deep: true
    }
  }
}
</script>
