<template>
  <div
    id="source-count-card-container"
    :class="errorCount > 0 || alertCount > 0 ? 'error-card' : ''"
  >
    <div>
      <img
        alt=""
        :src="require(`@/assets/icons/${imgUrl}-dashboard.svg`)"
        style="margin-left: 4px"
      />
      <span class="card-title">{{ $t(`source_type.${type}`) }}</span>
    </div>
    <div style="display: flex; margin-top: 9px">
      <div style="width: 33%; text-align: center">
        <p class="count-title">全部</p>
        <p class="count-value" @click="handleClick">{{ total }}</p>
      </div>
      <div style="width: 33%; text-align: center">
        <p class="count-title">异常</p>
        <p class="count-value error-value" @click="handleClick({ label: '异常', value: 'abnormal' })">{{ errorCount }}</p>
      </div>
      <div style="flex: 1; text-align: center">
        <p class="count-title">告警</p>
        <p class="count-value alert-value" @click="handleClick({ label: '告警', value: 'alert' })">{{ alertCount }}</p>
      </div>
    </div>

    <source-table-modal
      ref="tableModal"
      :title="`${service.name} 关联` + $t(`source_type.${type}`) + '明细'"
    ></source-table-modal>
  </div>
</template>

<script>
import {
  getServiceDatabaseList,
  getServiceMiddlewareList,
  getServiceOSList,
  getServiceApplicationList,
  getServiceSiteList,
  getServiceSSLCertificateList
} from '@/api/service.js'
import SourceTableModal from '@/components/modal/SourceTableModal/index.vue'

export default {
  name: 'SourceCountCard',
  components: {
    SourceTableModal
  },
  props: {
    type: {
      type: String
    },
    service: {
      type: Object,
      default: () => {
        return {
          applications: [],
          sites: [],
          ssl_certificates: []
        }
      }
    }
  },
  data () {
    return {
      errorCount: 0,
      total: 0,
      alertCount: 0
    }
  },
  computed: {
    imgUrl () {
      if (this.type === 'application') {
        return 'network_device'
      }
      if (this.type === 'site') return 'storage'
      return this.type
    }
  },
  mounted () {
    this.errorCount = 0
    if (this.service.id) {
      this.fetch()
    }
  },
  methods: {
    fetch () {
      let getListFunc
      switch (this.type) {
        case 'os':
          getListFunc = getServiceOSList
          break
        case 'middleware':
          getListFunc = getServiceMiddlewareList
          break
        case 'database':
          getListFunc = getServiceDatabaseList
          break
        case 'application':
          getListFunc = getServiceApplicationList
          break
        case 'site':
          getListFunc = getServiceSiteList
          break
        case 'ssl_certificate':
          getListFunc = getServiceSSLCertificateList
          break
      }
      if (getListFunc) {
        getListFunc(this.service.id).then(res => {
          this.total = res.data.total
          res.data.data.forEach(item => {
            if (item.status === 'abnormal') this.errorCount++
            if (item.status === 'alert') this.alertCount++
          })
        })
      }
    },
    handleClick (params) {
      this.$refs.tableModal.show(this.type, this.service.id, params)
    }
  },
  watch: {
    service () {
      this.errorCount = 0
      if (this.service.id) {
        this.fetch()
      }
    }
  }
}
</script>

<style lang="less">
#service-dashboard-detail-container {
  #source-count-card-container {
    height: 110px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #c6ddf6;
    padding: 10px;
    margin-bottom: 20px;
    position: relative;

    p {
      margin: 0;
    }

    .card-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      padding-left: 8px;
      vertical-align: middle;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 50%;
      display: inline-block;
      cursor: default;
    }

    .count-title {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      cursor: default;
      padding-top: 3px;
    }

    .count-value {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 25px;
      cursor: pointer;
    }

    .error-value {
      color: #ff4d4f;
    }

    .alert-value {
      color: #faad14;
    }
  }
  .error-card::after {
    position: absolute;
    top: -5px;
    right: -5px;
    content: '';
    width: 14px;
    height: 14px;
    background: #ff4d4f;
    border-radius: 50%;
    animation: flash 0.8s linear infinite alternate;
    opacity: 1;
  }

  @keyframes flash {
    to {
      opacity: 0;
    }
  }
}
</style>
