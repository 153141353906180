<template>
  <div id="performance-left-chart" style="height: 206px; width: 90%; margin-left: 12px; margin-top: 14px;"></div>
</template>

<script>
import { Chart, registerShape } from '@antv/g2'
let chart = null

// 自定义Shape 部分
registerShape('point', 'pointer', {
  draw (cfg, container) {
    const group = container.addGroup()
    const center = this.parsePoint({ x: 0, y: 0 }) // 获取极坐标系下画布中心点
    // 绘制指针
    group.addShape('line', {
      attrs: {
        x1: center.x,
        y1: center.y,
        x2: cfg.x,
        y2: cfg.y,
        stroke: cfg.color,
        lineWidth: 3,
        lineCap: 'round'
      }
    })
    group.addShape('circle', {
      attrs: {
        x: center.x,
        y: center.y,
        r: 4,
        stroke: cfg.color,
        lineWidth: 2,
        fill: '#fff'
      }
    })

    return group
  }
})
export default {
  name: 'PerformanceLeftChart',
  props: {
    chartData: {
      type: Array
    }
  },
  // data () {
  //   return {
  //     chartData: [{ value: 56.4 }]
  //   }
  // },
  mounted () {
    chart = new Chart({
      container: 'performance-left-chart',
      autoFit: true,
      padding: [10, 0, 10, 0]
    })
    chart.scale('value', {
      min: 0,
      max: 100,
      tickInterval: 10
    })
    chart.coordinate('polar', {
      startAngle: (-8 / 8) * Math.PI,
      endAngle: (0 / 8) * Math.PI,
      radius: 0.8
    })

    chart.axis('1', false)
    chart.axis('value', {
      line: null,
      label: {
        offset: -38,
        style: {
          fontSize: 12,
          textAlign: 'center',
          textBaseline: 'middle',
          fill: '#686E7E'
        },
        formatter: (v) => {
          if (v % 20 === 0) return v
        }
      },
      // tickLine: null,

      subTickLine: {
        count: 4,
        length: -20
      },
      tickLine: {
        length: -26
      },
      grid: null
    })
    chart.legend(false)
    chart
      .point()
      .position('value*1')
      .shape('pointer')
      .color('#1890FF')
      .animate({
        appear: {
          animation: 'fade-in'
        }
      })
    this.render()
    this.$forceUpdate()
  },
  methods: {
    render () {
      chart.annotation().clear(true)
      this.drawArc()
      chart.changeData(this.chartData)
    },
    drawArc () {
      chart.annotation().arc({
        top: false,
        start: [0, 1],
        end: [100, 1],
        style: {
        // 底灰色
          stroke: '#F2F6F7',
          lineWidth: 6,
          lineDash: null
        }
      })
      chart.annotation().text({
        position: ['50%', '0%'],
        content: `${this.chartData[0].value}%`,
        style: {
          fontSize: 26,
          fill: '#096DD9',
          fontWeight: 500,
          textAlign: 'center'
        },
        offsetY: 16
      })
      chart.annotation().arc({
        start: [0, 1],
        end: [this.chartData[0].value, 1],
        style: {
          stroke: '#1890FF',
          lineWidth: 10,
          lineDash: null
        }
      })
    }
  },
  watch: {
    chartData: {
      handler () {
        this.render()
      },
      deep: true
    }
  }
}
</script>
